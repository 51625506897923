import * as React from "react";
import Layout from "../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import Obfuscate from "react-obfuscate";

const AboutPage = () => {
  return (
    <Layout>
      <div className="card full-width">
        <div className="card-content">
          <h1 className="title" id="title">
            Standaufsichten
          </h1>
          <p>
            <span className="is-size-4 has-text-centered has-text-weight-medium">
              Für leere Felder kann man sich noch freiwillig melden!
            </span>
          </p>
          <p className="mb-4">
            Zu diesem Zweck bitte bei Fredy Schüßler unter{" "}
            <Obfuscate tel="0157 848 78 663" /> melden.
          </p>
          <div className="columns">
            <div className="column">
              <form
                style={{ display: "inline" }}
                action="https://aufsicht.sgi-flieden.de/pdf/autodownload"
                method="get"
              >
                <button className="button is-link is-large is-fullwidth mb-4">
                  <span className="icon">
                    <FontAwesomeIcon icon={faFileDownload} />
                  </span>
                  <span>Standaufsichtsplan</span>
                </button>
              </form>
            </div>
            <div className="column">
              <form
                style={{ display: "inline" }}
                action="/static/Schiessmeister2024.pdf"
                method="get"
              >
                <button className="button is-dark is-large is-fullwidth mb-4">
                  <span className="icon">
                    <FontAwesomeIcon icon={faFileDownload} />
                  </span>
                  <span>Schießmeisterkontakte</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
